import axios from 'axios'
import { notification } from 'antd'
import { store } from 'index'

const authClient = axios.create({
  baseURL: process.env.REACT_APP_AUTH_BACKEND_LINK,
  timeout: 10000,
  headers: {
    'x-app-source': 'consumer',
    'x-Device-Source': '2',
    'Api-Secret': process.env.REACT_APP_AUTH_SECRET,
  },
})

const apiClient = axios.create({
  baseURL: process.env.REACT_APP_GNG_BACKEND_LINK,
  timeout: 10000,
  headers: {
    'Api-Secret': process.env.REACT_APP_GNG_SECRET,
  },
})

apiClient.interceptors.request.use((request) => {
  const token = store.getState()?.user?.token || process.env.REACT_APP_SAMPLE_TOKEN

  if (token) {
    request.headers.Authorization = `Bearer ${token}`
  }
  return request
})

apiClient.interceptors.response.use(
  (res) => {
    // console.log('RESPONSE: ', res)
    return res
  },
  (err) => {
    const statusCode = err?.response?.data?.code || 'BackendOff'
    // console.log('ERROR: ', statusCode, err.response, err)

    switch (statusCode) {
      case 401:
        store.dispatch({
          type: 'user/LOGOUT',
        })
        break
      default:
        notification.error({
          message: statusCode,
          description: err?.response?.data?.code?.message,
        })
        throw err
    }
  },
)

authClient.interceptors.response.use(
  (res) => {
    // console.log('RESPONSE: ', res)
    return res
  },
  (error) => {
    // Errors handling
    const { response } = error
    const data = response?.data
    // console.log(error, data)

    if (data) {
      notification.error({
        message: data.code,
        description: data.message,
      })
    }
  },
)

class Axios {
  static async makeReq(Method, Url, Data, Params) {
    const response = await apiClient({
      url: Url,
      method: Method,
      data: Data,
      params: Params,
    })
    return response
  }
}

export { authClient, Axios }
