import { all, put, select, takeEvery } from 'redux-saga/effects'
import moment from 'moment'
import { TIME } from '../../../constants'
import actions from './actions'

export function* SET_GRP_CACHE({ payload }) {
  const infoPayload = {}

  infoPayload[payload.id] = {
    time: new Date(),
    status: payload.status,
  }

  yield put({
    type: 'cache/SET_GRP_CACHE',
    payload: {
      ...infoPayload,
    },
  })
}

export function* SET_FILTER_CACHE({ payload }) {
  yield put({
    type: 'cache/SET_FILTER_CACHE',
    payload: {
      date: new Date(),
      ...payload,
    },
  })
}

export function* CLEAR_GRP_CACHE() {
  const cachestate = yield select((state) => state.cache.groups)

  if (!!cachestate && Object.keys(cachestate).length > 0) {
    Object.entries(cachestate).forEach((ele) => {
      if (moment(ele[1].time).add(TIME, 'milliseconds').toDate() < new Date()) {
        delete cachestate[ele[0]]
      }
    })
  }

  yield put({
    type: 'cache/CLEAR_GRP_CACHE',
    payload: { ...cachestate },
  })
}

export function* CLEAR_FILTER_CACHE() {
  yield put({
    type: 'cache/CLEAR_FILTER_CACHE',
  })
}

export default function* rootSaga() {
  yield all([
    CLEAR_GRP_CACHE(), // run once on app load to clear cache
    CLEAR_FILTER_CACHE(),
    takeEvery(actions.PUT_GRP, SET_GRP_CACHE),
    takeEvery(actions.WASH_GRP, CLEAR_GRP_CACHE),
    takeEvery(actions.PUT_FILTER, SET_FILTER_CACHE),
    takeEvery(actions.WASH_FILTER, CLEAR_FILTER_CACHE),
  ])
}
