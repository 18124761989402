export default async function getMenuData() {
  return [
    {
      title: 'Dashboard',
      key: 'dashboard',
      url: '/dashboard',
      icon: 'fe fe-home',
    },
    {
      title: 'Groups',
      key: 'groups',
      url: '/groups',
      icon: 'fe fe-shuffle',
    },
    {
      title: 'Games',
      key: 'games',
      url: '/games',
      icon: 'fe fe-play',
    },
    {
      title: 'Users',
      key: 'users',
      url: '/users',
      icon: 'fe fe-users',
    },
    {
      title: 'Analytics',
      key: 'analytics',
      url: '/analytics',
      icon: 'fe fe-bar-chart',
    },
  ]
}
