import { FacebookOutlined, InstagramOutlined, YoutubeOutlined } from '@ant-design/icons'
import React from 'react'
import { connect } from 'react-redux'
import { Layout, Space } from 'antd'
import { withRouter } from 'react-router-dom'
import classNames from 'classnames'
import style from './style.module.scss'
import logoImage from '../../../public/resources/images/hudle/hudle-blue.png'

const mapStateToProps = ({ settings }) => ({
  logo: settings.logo,
  isGrayTopbar: settings.isGrayTopbar,
  isCardShadow: settings.isCardShadow,
  isSquaredBorders: settings.isSquaredBorders,
  isBorderless: settings.isBorderless,
  authPagesColor: settings.authPagesColor,
  isAuthTopbar: settings.isAuthTopbar,
})

const AuthLayout = ({
  children,
  // logo,
  isGrayTopbar,
  isCardShadow,
  isSquaredBorders,
  isBorderless,
  authPagesColor,
  isAuthTopbar,
}) => {
  return (
    <Layout className="vb__layout">
      <Layout.Content>
        <div
          className={classNames(`${style.container}`, {
            vb__layout__squaredBorders: isSquaredBorders,
            vb__layout__cardsShadow: isCardShadow,
            vb__layout__borderless: isBorderless,
            [style.white]: authPagesColor === 'white',
            [style.gray]: authPagesColor === 'gray',
          })}
          style={{
            backgroundImage:
              authPagesColor === 'image' ? 'url(resources/images/content/photos/8.jpeg)' : '',
          }}
        >
          {isAuthTopbar && (
            <div
              className={classNames(`${style.topbar}`, {
                [style.topbarGray]: isGrayTopbar,
              })}
            >
              <div className={style.Container}>
                <div className={style.logo}>
                  <a href="https://www.hudle.in/" target="_blank" rel="noreferrer">
                    <img src={logoImage} alt="Hudle-logo" width="80px" height="33px" />
                  </a>
                </div>
              </div>
            </div>
          )}
          <div className="mb-5">{children}</div>
          <div>
            <div className="text-center">
              Copyright © {new Date().getFullYear()}{' '}
              <a href="https://www.hudle.in/" target="_blank" rel="noopener noreferrer">
                HSquare Sports Private Limited
              </a>
              {' | '}
              <p>All Rights Reserved</p>
              <div>
                <Space align="center">
                  <a href="https://www.facebook.com/hudleindia/" rel="noreferrer" target="_blank">
                    <FacebookOutlined style={{ fontSize: '200%', marginRight: '30px' }} />
                  </a>
                  <a href="https://www.instagram.com/hudleplay/" rel="noreferrer" target="_blank">
                    <InstagramOutlined style={{ fontSize: '200%', marginRight: '30px' }} />
                  </a>
                  <a
                    href="https://www.youtube.com/channel/UC3zaLcb7J_UetpQM-ohFomA"
                    rel="noreferrer"
                    target="_blank"
                  >
                    <YoutubeOutlined style={{ fontSize: '200%', marginRight: '30px' }} />
                  </a>
                </Space>
              </div>
            </div>
          </div>
        </div>
      </Layout.Content>
    </Layout>
  )
}

export default withRouter(connect(mapStateToProps)(AuthLayout))
