const actions = {
  PUT_GRP: 'cache/PUT_GRP_CACHE',
  SET_GRP: 'cache/SET_GRP_CACHE',
  WASH_GRP: 'cache/WASH_GRP_CACHE',
  CLEAR_GRP: 'cache/CLEAR_GRP_CACHE',
  PUT_FILTER: 'cache/PUT_FILTER_CACHE',
  SET_FILTER: 'cache/SET_FILTER_CACHE',
  CLEAR_FILTER: 'cache/CLEAR_FILTER_CACHE',
  WASH_FILTER: 'cache/WASH_FILTER_CACHE',
}

export default actions
