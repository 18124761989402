import { authClient } from 'services/axios'

export async function login(email, password) {
  return authClient
    .post('/login', {
      email,
      password,
    })
    .then((response) => {
      const data = response.data.data || null
      if (data) {
        return {
          id: data.id,
          name: data.name,
          avatar: data.photo,
          gender: data.gender,
          email: data.email,
          phone: data.phone_number,
          dob: data.date_of_birth,
          role: data.types,
          authorized: data.is_approved,
          token: data.token,
          phone_verified: data.is_phone_verified,
          joined: data.created_at,
          logged: true,
        }
      }

      return null
    })
    .catch(() => {
      // console.log(err)
    })
}

export async function register() {
  // return authClient
  //   .post('/auth/register', {
  //     email,
  //     password,
  //     name,
  //   })
  //   .then((response) => {
  //     if (response) {
  //       const { accessToken } = response.data
  //       if (accessToken) {
  //         store.set('accessToken', accessToken)
  //       }
  //       return response.data
  //     }
  //     return false
  //   })
  //   .catch((err) => console.log(err))
  // return Axios.makeReq('POST', '/auth/register', {
  //   email,
  //   password,
  //   name,
  // })
}
