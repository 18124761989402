export const memberRoles = {
    normal: 0,
    admin: 1,
    notJoined: null
}

export const memberStatus = {
    approved: 0,
    unapproved: 1,
    notJoined: 2,
    banned: 3,
    removed: 4,
    exited: 5,
}

export const TIME = 5 * 60 * 1000

export const filterNames = ["sports","venues","cities"]