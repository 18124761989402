import actions from './actions'

const initialState = {}

export default function notifyReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_GRP:
      return { ...state, groups: { ...state.groups, ...action.payload } }
    case actions.CLEAR_GRP:
      return { ...state, groups: { ...action.payload } }
    case actions.SET_FILTER:
      return { ...state, filters: { ...state.filters, ...action.payload } }
    case actions.CLEAR_FILTER:
      return { ...state, filters: {} }
    default:
      return state
  }
}
