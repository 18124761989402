import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'

import config from './redux'
import sagas from './redux/sagas'
import StylesLoader from './stylesLoader'
import Localization from './localization'
import Router from './router'
import * as serviceWorker from './serviceWorker'

const { store, persistor, history, sagaMiddleware } = config()
sagaMiddleware.run(sagas)

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <StylesLoader>
        <Localization>
          <Router history={history} />
        </Localization>
      </StylesLoader>
    </PersistGate>
  </Provider>,
  document.getElementById('root'),
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
export { store, history }
